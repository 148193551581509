// Custom.scss
// Include parts of Bootstrap

// Required
@import "../libs/bootstrap-4.1.3/scss/_functions.scss";
@import "../libs/bootstrap-4.1.3/scss/_variables.scss";
@import "../libs/bootstrap-4.1.3/scss/_mixins.scss";



// Variable overrides / customisation

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 360px,
  md: 784px,
  lg: 1152px,
  xl: 1440px
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 1152px,
  xl: 1440px
);

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12;
$grid-gutter-width:           32px;


@import "../libs/bootstrap-4.1.3/scss/_grid.scss";

